<template>
  <h1>登录页面</h1>
  <Login></Login>
</template>

<script>
import Login from "@/components/Login.vue";
export default {
  components: {
    Login,
  },
};
</script>

<style scoped>
h1 {
  margin-top: 1rem;
}
</style>
